
export const checkTileParameterScenario = (finalTileObject,setIrisContext,setSearchTerm,setTileName)=>{
  if (finalTileObject["isIncidentTile"] && finalTileObject["isLoaded"] === false) {
    onIncident(finalTileObject, setIrisContext, setSearchTerm, setTileName);
  }
}


export const   checkIrisUtterance = (irisUtterance,setShowBot,setcontext,setTileName,tileName,setChatBotClicked,setIrisContext) =>{
    if (irisUtterance !== null) {
      setShowBot(false);
      setShowBot(true);
      setcontext("set context");
      
      setTileName(tileName);
      setChatBotClicked(false);
    } else {
      setIrisContext("");
    }
  }


const   onIncident = (finalTileObject,setIrisContext,setSearchTerm,setTileName) => {
    if (
      finalTileObject["isIncidentTile"] &&
      finalTileObject["IrisUtterance"] === "create support ticket"
    ) {
      setIrisContext(finalTileObject["IrisUtterance"]);
      setSearchTerm(finalTileObject["TileName"]);
      setTileName(finalTileObject["TileName"]);
      finalTileObject["isLoaded"]=true;
    } else {
      setIrisContext(finalTileObject["IrisUtterance"]);
      setTileName(finalTileObject["TileName"]);
      finalTileObject["isLoaded"]=true;
    }
  }


export const  finalIrisUtterance = (irisContext,propsIrisUtterance)=>{
  let irisUtteranceOring = irisContext !== "" || irisContext !== null
  let finalIrisUtterance = irisUtteranceOring && propsIrisUtterance === "" ? irisContext : propsIrisUtterance;
  return finalIrisUtterance
}

export const  finalSearchTerm = (searchTerm,propsSearchTerm)=>{
   let searchTermOring =  searchTerm !== "" || searchTerm !== null 
  let finalSearchTerm = searchTermOring && propsSearchTerm === "" ? searchTerm : propsSearchTerm;
  return finalSearchTerm
}

export const finalTileName = (tileName,propsTileName)=>{
  let tileNameOring = tileName !== "" || tileName !== null
  let finalTileName = tileNameOring && propsTileName === "" ? tileName : propsTileName
  return finalTileName
}




export   const isIrisMenuINDOM = (irisMenu,reduxDispatch,setIsLoader,iresbot,checkIrisMenu) => {
    if (irisMenu?.innerHTML !== "") {
      setTimeout(() => {
        reduxDispatch(setIsLoader(false));
      iresbot=true;
      }, 3000);
    } else {
      reduxDispatch(setIsLoader(true));
      // If irisMenu is not found, retry after 3 seconds
      setTimeout(checkIrisMenu, 40);
    }
   }

