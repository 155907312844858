import { MessageData } from "./data";

export interface MessageStreamingData {
    responseId: string;
    message: MessageData;
    loadingState: boolean;
}

export class LLMClient {
    private onUpdate: (data: MessageStreamingData) => void;

    constructor(onUpdate: (data: MessageStreamingData) => void) {
        this.onUpdate = onUpdate;
    }

    requestResponse(message: string): Promise<{ message: MessageData; suggestions: string[] }> {
        return new Promise((resolve) => {
            // Simulate an API call to get message and suggestions
            setTimeout(() => {
                const responseMessage: MessageData = {
                    id: 'copilot-1',
                    message: `Copilot response to "${message}"`,
                    timestamp: new Date().toLocaleTimeString(),
                    type: 'copilot'
                };
                resolve({
                    message: responseMessage,
                    suggestions: ['Suggestion 1', 'Suggestion 2'],
                });
            }, 1000);
        });
    }
}
