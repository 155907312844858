/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect,  useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrUserData,getChildComponentName, getServiceRequestTypeLogData, getLogEventData, SETHasServiceRequestTypeLogData, SETLogEventData, SETServiceRequestTypeLogData, getIsCustomeLog, SETIsCustomeLog }  from '../core/store';

const EventLoggingProviderFC = ({ parentContext, children }) => {

   const [childComponentName, setChildComponentName] = useState('');
  
  
  const reduxDispatch = useDispatch();

  const usersettings = useSelector(getCurrUserData);
  const childCompntName = useSelector(getChildComponentName);
  
  const ServiceRequestTypeLog = useSelector(getServiceRequestTypeLogData);
  const LogEvent = useSelector(getLogEventData);
  const IsCustomLog = useSelector(getIsCustomeLog);


  
  useEffect(() => {
    handleUserSettingsData(usersettings);
  }, [usersettings]);

  useEffect(() => {
    if(IsCustomLog){
    setCustomLogUserAction();
    }
  }, [IsCustomLog]);

  useEffect(() => {
   setChildComponentName(childCompntName);
   }, [childCompntName]);


 
  useEffect(() => {
    handleServiceReqTypeLogData(ServiceRequestTypeLog);
   
   }, [ServiceRequestTypeLog]);

  
  useEffect(() => {
    handlelogEventDataData(LogEvent);
    
  }, [LogEvent]);

 

  const setCustomLogUserAction = () => {
    



   
    const evtTelementry = { name: 'MCAPSHELP Portal' };
    
    const userSettingsData = handleUserSettingsData(usersettings);
    const logEventData = handlelogEventDataData(LogEvent);
    const serviceReqTypeLog = handleServiceReqTypeLogData(ServiceRequestTypeLog);
    const customElement = {
      contextName: window.location.pathname,
      pageName: childComponentName,
      ...userSettingsData,
      ...logEventData,
      ...serviceReqTypeLog
    };
    const telemetry = parentContext.telemetryClient;
    telemetry.trackEvent(evtTelementry, customElement);

    reduxDispatch(SETHasServiceRequestTypeLogData(false));
    reduxDispatch(SETServiceRequestTypeLogData({}));
    reduxDispatch(SETLogEventData({}));
    reduxDispatch(SETIsCustomeLog(false));
    
};
  

  const handleUserSettingsData = (data:any) => {

    return { 
       "User" : data.upn||'',
       "UserSettingsArea" : data.userAreaName ||'',
       "UserSettingsRole" : data.userRoleName ||'',
       "UserSettingsSegment" : data.userSegmentName ||'',
       "UserSettingsSubSegment" : data.userSubSegmentName ||''
   
    };

 
 };

 const handlelogEventDataData = (data:any) => {
  if(Object.keys(data).length !== 0)
   return { 
        elementName: data.elementName ,
        elementId: data.elementId,
        pageName: childCompntName,
        ...data
    
     }
  }


  const handleServiceReqTypeLogData = (data:any) => {
   return { 
        "ServiceName" : data.ServiceName ||'',
        "RequestType" : data.RequestType ||'',
        "TileName" : data.TileName ||'',
        "IsNonIrisService" : data.IsNonIrisService ||'',
        "IrisUtterance" : data.IrisUtterance ||'',
        "SearchTerm" : data.SearchTerm||'',
     }
  }
 

 
  

  return <>{children}</>;
};

export default EventLoggingProviderFC;