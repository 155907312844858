import { callapi } from "./APIHelperFile";
import { AREA, DOWNLOAD_FILE, FILE_TYPE, IRIS_UTTERANCE, MODE, MODE_QUERY, TILE_NAME , SEGMENT, TILE_INFO, SEND_SERVICE_GROUP, DEFAULT_SERVICE_GROUP } from "../../utils/constants";
import { ALLOWED_PARAMETERS } from "../../utils/constants";
import { INCIDENT_TILE_UTTERANCE, SERVICE_NOT_VALID } from "../../utils/constants";
 
const getTilesData = (params, identifyTile, finalTileObject, context) => {
  let shouldDownloadFile = false;
  params.map((e) => {
    let [key, value] = e.split("=");
    identifyTile.push(key.toLowerCase());
    switch (key.toLowerCase()) {
      case "tilename":
        finalTileObject["TileName"] = decodeURIComponent(value);
        return;
      case "irisutterance":
        finalTileObject["IrisUtterance"] = decodeURIComponent(value);
        return;
      case "area":
        finalTileObject["Area"] = decodeURIComponent(value);
        return;
      case "mode":
        finalTileObject["Mode"] = decodeURIComponent(value.toLowerCase());
        return;
      case "downloadfile":
        finalTileObject["downloadfile"] = decodeURIComponent(value.toLowerCase());
        shouldDownloadFile = true;
        return;
      case "typeoffeedback":
        finalTileObject["typeOfFeedback"] = decodeURIComponent(value.toLowerCase());
        shouldDownloadFile = true;
        return;
      case "segment":
        finalTileObject["Segment"] = decodeURIComponent(value.toLowerCase());
        return;
      case "tileinfo":
        finalTileObject["tileinfo"] = decodeURIComponent(value);
        return;
      case "sendservicegroup":
        finalTileObject["sendServiceGroup"] = decodeURIComponent(value);
        return;
      case "defaultservicegroup":
        finalTileObject["defaultServiceGroup"] = decodeURIComponent(value);
        return;
      default:
        return;
    }
  });

  if (shouldDownloadFile) {
    return true; // Do not call the API and return true if downloadfile is present
  }

  let result = callapi(
    context,
    finalTileObject["TileName"],
    finalTileObject["IrisUtterance"],
    finalTileObject["Area"]
  );
  
  return result;
};

 
const checkIncidentTile = (identifyTile, finalTileObject) => {
  if (
    identifyTile.includes(TILE_NAME) &&
    identifyTile.includes(IRIS_UTTERANCE) &&
    identifyTile.includes(MODE) &&
    MODE_QUERY.includes(finalTileObject["Mode"]) &&
    finalTileObject["IrisUtterance"].includes(INCIDENT_TILE_UTTERANCE)
  ) {
    return true;
  } else if (
    identifyTile.includes(TILE_NAME) &&
    identifyTile.includes(MODE) &&
    MODE_QUERY.includes(finalTileObject["Mode"]) &&
    !finalTileObject["Mode"].includes("service request")
  ) {
    finalTileObject["IrisUtterance"] = "";
    return true;
  }
  return false;
};
 
 
 const checkNonAmericaWithIrisUtterance = (identifyTile,finalTileObject)=>{
  if(identifyTile.includes(TILE_NAME) &&
  identifyTile.includes(IRIS_UTTERANCE) &&
  !finalTileObject["IrisUtterance"].includes(INCIDENT_TILE_UTTERANCE))
  {
    return true;
  }else{
    return false;
  }
 }
 
const checkNonAmerica = (identifyTile, finalTileObject) => {

  if (
    checkNonAmericaWithIrisUtterance(identifyTile, finalTileObject) 
    //checkNonAmericaWithoutIrisUtterance(identifyTile, finalTileObject)
  ) {
    return true;
  } else {
    return false;
  }
};
 
const checkAmerica = (identifyTile, finalTileObject) => {
  if (identifyTile.includes(TILE_NAME) && identifyTile.includes(AREA) && !identifyTile.includes(SEGMENT)) {
    return true;
  }
  return false;
};


const checkAmericaSMC = (identifyTile, finalTileObject) => { 
  if (identifyTile.includes(TILE_NAME) && identifyTile.includes(AREA) && identifyTile.includes(SEGMENT)) {
    return true;
  }
  return false;
};

const checkFeedbackUrlDownload = (identifyTile, finalTileObject) => {
  if (identifyTile.includes(DOWNLOAD_FILE) || identifyTile.includes(FILE_TYPE)) {
    return true;
  }
  return false;
};

const getTileFinalScenario = (identifyTile, finalTileObject) => {
  if (checkIncidentTile(identifyTile, finalTileObject)) {
    finalTileObject["isIncidentTile"] = true;
  } else if (checkAmerica(identifyTile, finalTileObject)) {
    finalTileObject["isAmerica"] = true;
  } else if (checkNonAmerica(identifyTile, finalTileObject)) {
    finalTileObject["isNonAmerica"] = true;
    if( finalTileObject["tileinfo"] == "true"){
      finalTileObject["tileinfo"] = true;
    }
  } else if (checkAmericaSMC(identifyTile, finalTileObject)) {
    finalTileObject["isAmericaSMC"] = true;
  }
  else if(checkFeedbackUrlDownload(identifyTile, finalTileObject)){
      finalTileObject["isFeedbackUrlDownload"] = true;
  }
  else {
    finalTileObject["Error"] = SERVICE_NOT_VALID;
  } 
  if( finalTileObject["sendservicegroup"] == "true"){
    finalTileObject["sendservicegroup"] = true;
  }
};
 
const validateParameter = (identifyTile) => {
  for (let param of identifyTile) {
    if (!ALLOWED_PARAMETERS.includes(param)) {
      return false;
    }
  }
  return true;
};
 
export const GetTheTileConfiguration = async (
  finalTileObject: any,
  context: any
) => {
  const query = window.location.search;
 
  if (query === "") {
    return "";
  } else {
    const params = query.slice(1).split("&");
    let identifyTile = [];
    let result = await getTilesData(
      params,
      identifyTile,
      finalTileObject,
      context
    );
    if (validateParameter(identifyTile) && result === true) {
      getTileFinalScenario(identifyTile, finalTileObject);
      finalTileObject["isValidated"] = true;
      return finalTileObject;
    } else {
      finalTileObject["Error"] = SERVICE_NOT_VALID;
      return finalTileObject;
    }
  }
};

