import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  pageTile: {
    id: 'notFoundPage.title',
    defaultMessage: 'Page Not Found'
  },
  pageDescription: {
    id: 'notFoundPage.description',
    defaultMessage: "We can't find the page you're lookng for. Please check the URL. You can either return to the previous page or click below link to redirect to Home page."
  },
  homePage: {
    id: 'notFoundPage.homePageLinkLabel',
    defaultMessage: 'Visit Home'
  },
});

