import { defineMessages } from "react-intl";

export const messages = defineMessages({
  subTitle: {
    id: "signinPage.subTitle",
    defaultMessage: "gives you focus, from work to play.",
  },
  getStartedButtonText: {
    id: "signinPage.getStartedButtonText",
    defaultMessage: "Sign in",
  },
  learnButtonText: {
    id: "signinPage.learnButtonText",
    defaultMessage: "Learn more",
  },
  waitText: {
    id: "signinPage.waitText",
    defaultMessage: "Please wait...",
  },
  authText: {
    id: "signinPage.authText",
    defaultMessage: "Authorizing User...",
  },
});
