import { EditRegular, SparkleRegular } from "@fluentui/react-icons";
export interface MessageData {
    id: string;
    message: string | JSX.Element;
    timestamp: string;
    type: 'user' | 'copilot' | 'system';
    imageUrl?: string,
    imageName?: string
}

export interface PromptData {
    icon: React.ReactNode;
    category: string;
    prompt: string;
    badge?: React.ReactNode;
}

export interface ZeroPromptProps {
    prompts: PromptData[];
    onPromptSelected?: (prompt: PromptData) => void;
}

export const INITIAL_PROMPTS: PromptData[] = [
    { icon: "", badge: "", category: 'Ask', prompt: 'How can I help you?' },
    { icon: "", badge: "", category: 'Guide', prompt: 'Let\'s get started...' }
];

export const SUGGESTIONS: string[] = [
    'How to set up Copilot?',
    'Tell me more about Fluent UI Copilot',
];

export const SYSTEM_MESSAGES: MessageData[] = [
    {
        id: 'system-1', message: 'Welcome to Copilot!', timestamp: '', type: 'system',

    },
];
