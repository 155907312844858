import { MessageData } from "./data";
import { MessageStreamingData } from "./llmClient";

export const formatCurrentTime = (): string => {
    return new Date().toLocaleTimeString();
};

export const updateMessageContent = (
    messageHistory: MessageData[],
    responseId: string,
    message: MessageStreamingData,
    loadingState: boolean
): MessageData[] => {
    return messageHistory.map((m) =>
        m.id === responseId ? { ...m, ...message.message } : m
    );
};
