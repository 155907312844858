import { getConsumptionAPI } from '../../utils/httpUtils';
import { getGraphTransitiveMemberOfFeedback } from '../../pages/Authorize/AuthorizeUtility';
import { USER_NOT_PART_OF_FEEDBACK_GROUP } from '../../utils/constants';

const getFileNameFromDisposition = (contentDisposition) => {
  if (contentDisposition?.includes('attachment')) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    return matches?.[1]?.replace(/['"]/g, '') || 'download';
  }
  return 'download';
};

const isUserPartOfGroup = async (context,isUserLoggedIn) => {

      try {
        if (context?.telemetryClient && isUserLoggedIn) {
          const response = await getGraphTransitiveMemberOfFeedback(context.authClient);
          console.log("response of graph member feedback",response)
          if (response?.status === 200) {
            console.log("response.data.value?.length",response.data.value?.length)
            return(response.data.value?.length > 0);
          }
        }
      } catch (error) {}
      
      return false; // 
    }

    const downloadFeedbackImage = async (finalTileObject, context, isUserLoggedIn) => {
      try {
          const isPartOfGroup = await isUserPartOfGroup(context, isUserLoggedIn);
          if (!isPartOfGroup) {
              // User is not part of the group
              return { ...finalTileObject, Error: USER_NOT_PART_OF_FEEDBACK_GROUP };
          }
  
          const url = `/api/feedback/DownloadFeedbackImage/${finalTileObject["downloadfile"]}/${finalTileObject["typeOfFeedback"]}`;
          const response = await getConsumptionAPI(url, context.authClient, 'blob');
  
          if (response.status === 200) {
              const blob = new Blob([response.data], { type: response.headers['content-type'] });
              const fileURL = window.URL.createObjectURL(blob);
  
              const link = document.createElement('a');
              link.href = fileURL;
              link.setAttribute('download', getFileNameFromDisposition(response.headers['content-disposition']));
  
              document.body.appendChild(link);
              link.click();
  
              document.body.removeChild(link);
              window.URL.revokeObjectURL(fileURL);
  
              return { ...finalTileObject, Success: true, Message: 'Feedback Attachment downloaded successfully' };
          } else {
              return { ...finalTileObject, Error: 'Error downloading the Feedback Attachment', ErrorMessage: `Response status: ${response.status}` };
          }
      } catch (error) {
          console.error('Error downloading the image:', error);
          return { ...finalTileObject, Error: 'Error downloading the image', ErrorMessage: error.message };
      }
  };
  

export const FeedbackScreenshotDownloader = async (finalTileObject,context,isUserLoggedIn) => {
  if (finalTileObject["downloadfile"]) {
    return await downloadFeedbackImage(finalTileObject, context,isUserLoggedIn);
  }
  return false; 
};

