import {
  Stack
} from "@fluentui/react";
import { Spinner } from "@fluentui/react";
import { injectIntl } from "react-intl";
import "./about.css";
const AboutComponent = () => {
  

  interface CustomWindow extends Window {
    popupTop?: number;
    popupLeft?: number;
  }

  (window as CustomWindow).popupTop = 270;
  (window as CustomWindow).popupLeft = 280;

 

  
  return (
    <>
      <div style={{ display: "flex" }}>
        <div className="aboutus">
          <Stack
            horizontal
            tokens={{ childrenGap: "20" }}
            className="parentContainer"
          >
            
            <Stack className="mainContent">
            
            </Stack>
          </Stack>
        </div>
        <div
          style={{
            flex: "25%",
            display: "flex",
            justifyContent: "center",
            alignItems: "top",
            marginTop: "20%",
          }}
        >
          <div className="loader">
            <Spinner label="Loading..." />
          </div>
        </div>
      </div>
    </>
  );
};

export const About = injectIntl(AboutComponent);
