import React from 'react';
import { Dropdown, PrimaryButton, DefaultButton } from '@fluentui/react'; // or wherever you import these from

interface FooterContentProps {
  buttonsCount: number;
  options: any[]; // replace with the actual type of your options
  selectedOption: any; // replace with the actual type of your selectedOption
  handleChange?:any;
  dialogTitle?:any;
  dialogSubText?:any;
  buttonTextOne?:any;
  buttonTextTwo?:any;
  onGetStarted?:any;
  onClose?:any;
  onCancel?:any;
  onConfirm?:any; // replace with the actual type of your itemId
  itemId?:any; // replace with the actual type of your itemId
  handleKeyDownGetStarted?:any;
  handleKeyDownClose?:any;
  handleKeyDownCancel?:any;
  handleKeyDownConfirm?:any; // replace with the actual type of your itemId
}

const FooterContent: React.FC<FooterContentProps> = (props) => {
    switch (props.buttonsCount) {
            case 0:
              return (
                <>
                  <Dropdown
                    placeholder="Select an option"
                    options={props.options}
                    selectedKey={props.selectedOption ? props.selectedOption.key : undefined}
                    onChange={props.handleChange}
                    className="customDropdown"
                    aria-label={props.dialogTitle + " " + props.dialogSubText}
                    styles={{
                      root: {
                        width: 300,
                        marginTop: -20,
                        marginBottom: 10,
                      },
                    }}
                  />
                  {props.selectedOption ? (
                    <>
                      <PrimaryButton
                        aria-label={props.buttonTextOne}
                        onClick={props.onGetStarted}
                        text={props.buttonTextOne}
                        styles={{ root: { marginRight: 10 } }}
                      />
                      <DefaultButton
                        onClick={props.onClose}
                        aria-label={props.buttonTextTwo}
                        text={props.buttonTextTwo}
                      />
                    </>
                  ) : (
                    <>
                    <PrimaryButton
                        onClick={props.onGetStarted}
                        aria-label={props.buttonTextOne}
                        disabled={true}
                        text={props.buttonTextOne}
                        styles={{ root: { marginRight: 10 } }}
                        onKeyDown={(ev: React.KeyboardEvent<HTMLButtonElement>) => props.handleKeyDownGetStarted(ev)}
                    />
                      <PrimaryButton
                        onClick={props.onClose}
                        aria-label={props.buttonTextTwo}
                        text={props.buttonTextTwo}
                        onKeyDown={(ev: React.KeyboardEvent<HTMLButtonElement>)=>props.handleKeyDownClose(ev)}
                      />
                    </>
                  )}
                </>
              );
            case 1:
              return (
                <PrimaryButton
                  aria-label={props.buttonTextTwo}
                  onClick={props.onCancel}
                  text={props.buttonTextOne === ""?props.buttonTextTwo:props.buttonTextOne}
                  onKeyDown={(ev: React.KeyboardEvent<HTMLButtonElement>)=>props.handleKeyDownCancel(ev)}
                  role="button"
                />
              );
            case 2:
              return (
                <>
                  <PrimaryButton
                    onClick={() => props.onConfirm(props.itemId)}
                    aria-label={props.buttonTextOne}
                    text={props.buttonTextOne}
                    styles={{ root: { marginRight: 10 } }}
                    onKeyDown={(ev: React.KeyboardEvent<HTMLButtonElement>)=>props.handleKeyDownConfirm(ev,props.itemId)}
                    role="button"
                  />
                  <DefaultButton
                    onClick={props.onCancel}
                    aria-label={props.buttonTextTwo}
                    text={props.buttonTextTwo}
                    onKeyDown={(ev: React.KeyboardEvent<HTMLButtonElement>)=>props.handleKeyDownCancel(ev)}
                    role="button"
                  />
                </>
              );
            default:
              return null;
          }
};

export default FooterContent;