
import { MessageBar, MessageBarType } from '@fluentui/react'
import React from 'react'

const ErrorComponent = (errorData, isWorkspaceValueError = false) => {
  return (
    <MessageBar
      messageBarType={MessageBarType.error}
      isMultiline={false}
      truncated={true}
      overflowButtonAriaLabel="See more"
      dismissButtonAriaLabel="Close"
      styles={{
        root: {
          width: '100%',
          justifyContent: 'center'
        },
      }}
    >

      {!errorData.isWorkspaceValueError ? (<>
        <strong>Status Code:</strong> {errorData.errorData.Code} <br />
        <strong>Correlation ID:</strong> {errorData.errorData.CorrelationId} <br />
        <strong>Message:</strong> {errorData.errorData.Message} </>) : (
        <><strong>Message:</strong> {errorData.errorData}</>
      )
      }

    </MessageBar>
  )
}

export default ErrorComponent