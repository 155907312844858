import {
  ITelemetryClient,
  IGraphClient,
  IHttpClient,
  withStore,
} from '@msx/platform-services';
import { MSALV2Client } from '../utils/MSALV2Client';
import {
  TelemetryClient,
  HttpClient,
  GraphClient,
  ReducerRegistry,
  StoreBuilder
} from '@msx/platform-services';
import { Shell } from '@msx/platform-services';
import { appConfig } from '../../app/App.config';
import { PublicClientApplication, LogLevel, AccountInfo } from "@azure/msal-browser";
 

interface IAuthClient {
  authContext: PublicClientApplication;
  login: () => Promise<void>;
  logout: () => Promise<void>;
  getUser: () => AccountInfo | null;
  // Add other methods as required
}

const telemetryClient: ITelemetryClient = new TelemetryClient(
  {
    instrumentationKey: appConfig.telemetryConfig.instrumentationKey,
    UTPConfig: appConfig.UTPConfig,
    defaultProperties: {
      appName: appConfig.appName,
    },
 
  });
 


  const authClientConfig = {
    auth: {
      clientId: appConfig.authConfig.clientId,
      redirectUri: window.location.origin,
      authority: appConfig.authConfig.authority,
      navigateToLoginRequestUrl: true,
    },
    cache: {
      
      storeAuthStateInCookie: true,
      cacheLocation: "sessionStorage",
      temporaryCacheLocation: "sessionStorage",
    },
    system: {
      loggerOptions: {
        logLevel: LogLevel.Trace,
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              break;
            case LogLevel.Info:
              console.info(message);
              break;
            case LogLevel.Verbose:
              console.debug(message);
              break;
            case LogLevel.Warning:
              console.warn(message);
              break;
            default:
              console.log(message);
              break;
          }
        },
      },
    },
  };
  
  // Instantiate MSALV2Client with the configuration
  const authClient = new MSALV2Client(authClientConfig, telemetryClient);
  
  const httpClient = new HttpClient(
    telemetryClient,
    authClient,
    { logPayload: appConfig.telemetryConfig.logPayload }
  );
  
  const graphClient = new GraphClient(httpClient);
  
  const initialAppState = {};
  const isDevelopment = process.env.NODE_ENV === 'development';
  const reducerRegistry = new ReducerRegistry();
  const storeResult = new StoreBuilder(reducerRegistry, initialAppState)
    .configureLogger(isDevelopment)
    .configureSaga({
      authClient,
      telemetryClient,
      httpClient,
      graphClient,
    })
    .build();
  
  export const AppShellProvider = withStore(storeResult)(Shell);