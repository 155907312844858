import { IAppTheme } from '@msx/platform-services'

export const getStyles = (theme: IAppTheme) => {
  return {
    root: {
      paddingTop: '20px',
    },
    tile: {
      margin: '10px 20px 20px 0',
    },
  }
};


