import {
  Checkbox,
  Dropdown,
  Label,
  PrimaryButton,
  Stack,
  TextField,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import React from "react";
import "./prompt.css";
import { ServiceContext } from "@msx/platform-services";
import {
  postConsumptionAPI,
  //   postConsumptionAPILocal,
} from "../../../app/utils/httpUtils";
import ShimmerLoader from "../../../app/components/main/Services/ShimmerLoader";
import PopUpModal from "../../../app/components/main/Services/PopUpPanel";
import { setSelectedCategories } from "../../store";

const Prompt = ({
  Arr,
  loadingPrompt,
  getPrompts,
  setLoadingPrompt,
  Category,
  selectedCategory,
}) => {
  const [finalCategory, setFinalCategory] = useState();
  const [dropKey, setDropKey] = useState<any>("");
  const [selectedKey, setSelectedKey] = useState();
  const [promptKey, setPromptKey] = useState("");
  const [prompt, setPrompt] = useState("");
  const [isNewButtonset, setIsNewButtonSet] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [showSubmitted, setShowSubmitted] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  const [body, setBody] = useState<any>();
  const[errorMessage,setErrorMessage]=useState("");
  const context = React.useContext(ServiceContext);

  useEffect(() => {
    filterCategorybasedKeys(selectedCategory);
  }, []);

  useEffect(() => {
    if (submitted) {
      promptKey !== "" &&
        InsertOrUpdatePrompt().then(() => getPromptsforReload());
    }
  }, [submitted]);

  const getPromptsforReload = () => {
    getPrompts();
    setLoadingPrompt(true);
    setPromptKey("");
    setPrompt("");
    setDropKey("");
    setIsNewButtonSet(false);
    setIsDefault(false);
  };

  const filterCategorybasedKeys = (e) => {
    const filteredKeys = Arr?.filter((prompt) => prompt.category === e);
    setDropKey(filteredKeys);
    const defaultKey =
      filteredKeys?.find((prompt) => prompt.isDefault === 1) || filteredKeys[0];
    setSelectedKey(defaultKey?.text);
    setPromptKey(defaultKey?.text);
    setFinalCategory(defaultKey?.category);
    setPrompt(defaultKey?.value);
  setIsDefault(defaultKey?.isDefault === 1);
    return filteredKeys;
  };

  const InsertOrUpdatePrompt = async () => {
    try {
      if (context && context.telemetryClient) {
        const response = await postConsumptionAPI(
          `/api/UserWorkSpace/InsertOrUpdateAiEnvironmentVariable`,
          body,
          context.authClient
        );
        if (response.status > 200 && response.status < 300) {
          alert("saved");
        }
      }
    } catch (error) {
      console.error("An error occurred while fetching data.");
    }
  };

  const handlePromptChange = (event, option) => {
    setIsDefault(false);

    setPromptKey(option.text);
    setPrompt(option.value);
  };

  const handleNewButton = (isChecked) => {
    setPromptKey("");
    setPrompt("");
    if (isChecked) {
      setIsNewButtonSet(true);
    } else {
      setIsNewButtonSet(false);
    }
    setIsNewButtonSet(!isNewButtonset);
  };

  const onNewPromptKey = (event) => {
    setPromptKey(event.target.value);
  };

  const onTextareaChange = (event) => {
    if (promptKey !== "") {
      setPrompt(event.target.value);
    }else{
      setErrorMessage("Please fill the New Prompt Key");
    }
  };

  const handleCategoryChange = (event, option) => {
    console.log(option);
    setIsNewButtonSet(false);
    setIsDefault(false);
    setFinalCategory(option.value);
    filterCategorybasedKeys(option.text);
  };

  const handleDefaultButton = (ev, isChecked) => {
    setIsDefault(isChecked);
  };

  const [showDefault, setShowDefault] = useState(false);
  const onsubmit = () => {
    sessionStorage.setItem('FinalCategory',finalCategory);
    const data = {
      promptKey: promptKey,
      promptValue: prompt,
      category: finalCategory,
      isDefault: isDefault ? 1 : 0,
      // isDefault: isDefault ? 1 : 0,
    };
    setBody(data);
    // isDefault ? setShowDefault(true):
    if (isDefault) {
      setShowDefault(true);
    } else {
      setShowSubmitted(true);
    }
  };

  const handleConfirmDefault = () => {
    setShowSubmitted(true);
    setShowDefault(false);
  };

  const handleCancelDefault = () => {
    setShowSubmitted(false);
    setShowDefault(false);
  };

  const ConfirmSubmit = () => {
    setSubmitted(true);
    setShowSubmitted(false);
    // promptKey !== "" && InsertOrUpdatePrompt(data).then(() => getPrompts());
    // setSubmitted(false);
  };

  return (
    <>
      <Stack
        verticalAlign="center"
        className="mainContainer"
        style={{ width: "80%", alignItems: "center" }}>
        {loadingPrompt && <ShimmerLoader count={2} />}
        {!loadingPrompt && (
          <Stack
            style={{
              alignItems: "baseline",
              height: "25rem",
            }}
            verticalAlign="center">
            <h3 className="Heading"> Prompt Configuration</h3>

            {/* Dropdown s  */}
            <Stack horizontal horizontalAlign="space-between">
              {isNewButtonset ? (
                <Stack verticalAlign="baseline">
                  <Label styles={{ root: { fontSize: "Large !important" } }}>
                    New Prompt
                  </Label>
                  <TextField
                    rows={1}
                    multiline
                    value={promptKey}
                    placeholder="Fill the Key for the new Prompt"
                    className="promptNewKey"
                    onChange={onNewPromptKey}
                    styles={{
                      field: {
                        "::placeholder": { fontSize: "large" },

                        fontSize: "large !important",
                      },
                      root: {
                        width: "35rem !important",
                        marginRight: "2rem",
                      },
                    }}
                  />
                </Stack>
              ) : (
                <Dropdown
                  options={dropKey}
                  defaultSelectedKey={selectedKey}
                  onChange={handlePromptChange}
                  label="Select Prompt"
                  styles={{
                    root: { width: "34rem", marginRight: "2rem" },
                    title: {
                      fontSize: "Larger",
                      height: "4rem",
                    },
                    label: {
                      fontSize: "Large !important",
                    },
                    caretDown: {
                      fontSize: "Large",
                      marginTop: "1rem",
                      marginRight: "1rem",
                    },
                  }}
                />
              )}
              <Dropdown
                options={Category}
                defaultSelectedKey={selectedCategory}
                onChange={handleCategoryChange}
                label="Category"
                className="categoryDropdown"
                styles={{
                  root: { width: "34rem" },
                  title: {
                    fontSize: "Larger",
                    height: "4rem",
                  },
                  label: {
                    fontSize: "Large !important",
                  },
                  caretDown: {
                    fontSize: "Large",
                    marginTop: "1rem",
                    marginRight: "1rem",
                  },
                }}
              />
            </Stack>

            {/* ------checkbox */}
            <Stack
              horizontal
              horizontalAlign="center"
              className="horizontalContainer">
              <Checkbox
                onChange={handleNewButton}
                label="New Prompt"
                styles={{
                  checkbox: {
                    height: "2rem",
                    width: "2rem",
                    marginLeft: "2rem",
                    marginTop: "0.5rem",
                  },
                  checkmark: { fontWeight: "bold", fontSize: "Large" },
                  label: { fontWeight: "700" },
                  text: { fontSize: "Larger", marginTop: "1rem" },
                }}
              />

              <Checkbox
                onChange={handleDefaultButton}
                checked={isDefault}
                label="Default (Check This To Make Prompt  Default)"
                styles={{
                  checkbox: {
                    height: "2rem",
                    width: "2rem",
                    marginLeft: "2rem",
                    marginTop: "0.5rem",
                  },
                  checkmark: { fontWeight: "bold", fontSize: "Large" },
                  label: { fontWeight: "700" },
                  text: { fontSize: "Larger", marginTop: "1rem" },
                }}
              />
            </Stack>

            {/* ------Prompt value */}
            <Label
              styles={{
                root: {
                  fontSize: "Large !important",
                  marginTop: "2rem",
                  marginBottom: "-2rem",
                },
              }}>
              Prompt
              <span className="" style={{marginLeft:"2rem",fontSize:"x-small",fontWeight:"700",color:"Red"}}>{(promptKey === null || promptKey === "") && errorMessage}</span>
            </Label>
            <TextField
              rows={5}
              multiline={true}
              className="PromptTextArea"
              value={prompt}
              onChange={onTextareaChange}
              styles={{
                field: {
                  "::placeholder": { fontSize: "large" },
                  fontSize: "large !important",
                },
                root: {
                  fontSize: "Large !important",
                  width: "100% !important",
                },
              }}
              placeholder="Here You can modify your prompt"
            />
            <PrimaryButton
              text="Submit"
              className="promptSubmit"
              onClick={onsubmit}
              disabled={prompt === "" || promptKey === "" ? true : false}
              styles={{ label: { fontSize: "x-large" } }}
            />

            {/* ------ */}
            <PopUpModal
              isVisible={showSubmitted}
              onConfirm={ConfirmSubmit}
              onCancel={ConfirmSubmit}
              onHide={ConfirmSubmit}
              dialogTitle={"Prompt Change Confirmation"}
              dialogSubText={"Your Request Is Submitted and Prompt is Changes"}
              buttonsCount={1}
              buttonTextOne={"OK"}
            />
            <PopUpModal
              isVisible={showDefault}
              onConfirm={handleConfirmDefault}
              onCancel={handleCancelDefault}
              onHide={handleCancelDefault}
              dialogTitle={"Setting to Default Confirmation"}
              dialogSubText={
                "Are you sure you want to set  this prompt as default"
              }
              buttonsCount={2}
              buttonTextOne={"Yes"}
              buttonTextTwo={"No"}
            />

            {/* </Stack> */}
          </Stack>
        )}
      </Stack>
    </>
  );
};
export default Prompt;
