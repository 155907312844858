import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ChatInput,
  ImperativeControlPlugin,
} from "@fluentui-copilot/react-copilot";
import { InjectedIntlProps,injectIntl } from "react-intl";
import type { ImperativeControlPluginRef } from "@fluentui-copilot/react-copilot";
import copilotlogo from './images/iris-copilot-icon.svg';
import {
  CopilotChat,
  Timestamp,
  UserMessageV2 as UserMessage,
  CopilotMessageV2 as CopilotMessage
} from "@fluentui-copilot/react-copilot-chat";
import {
  SETChileComponentName,
  SETHasServiceRequestTypeLogData,
  SETIsCustomeLog,
  SETLogEventData,
  SETServiceRequestTypeLogData,
  getIsUserDataUpdated,
  setIsBotError,
  setIsLoader,
  setPreferenceChanged,
  setRecommendedTiles,
  settitleLoaderName,
} from "../../../core/store";
import {
  Avatar,
  Button,
  Divider,
  makeStyles,
  Radio,
  RadioGroup,
  tokens,
} from "@fluentui/react-components";
import {
  EditRegular,
  BookmarkRegular,
  ShareRegular,
  ImageBorder32Regular
} from "@fluentui/react-icons";
import type { MessageStreamingData } from "./llmClient";
import { LLMClient } from "./llmClient";
import DummyData from  './dummyData.json';
import type { MessageData, PromptData } from "./data";
import { SYSTEM_MESSAGES, SUGGESTIONS } from "./data";

import { formatCurrentTime, updateMessageContent } from "./utils";
import {
  CopilotMessageWrapper,
  SystemMessageWrapper,
} from "./componentRenderers";
import {
  postConsumptionAPI
} from "../../../app/utils/httpUtils";
import { ServiceContext } from "@msx/platform-services";
const useStyles = makeStyles({
  provider: {
    backgroundColor: tokens.colorNeutralBackground3,
    padding: `${tokens.spacingVerticalL} ${tokens.spacingHorizontalL}`,
    borderRadius: tokens.borderRadiusXLarge,
    display: "flex",
    rowGap: "16px",
    flexDirection: "column",
  },
  chat: {
    height: "360px",
    marginBottom: "100px",
    padding: `${tokens.spacingVerticalL} ${tokens.spacingHorizontalL}`,
    overflowY: "auto",
    OverflowX:'hidden',
    width: '100%',
    maxWidth: 'calc(100% - 26%)',
    scrollbarWidth:'none'
  },
 
  inputContainer: {
    display: "flex",
    alignItems: "center",
  },
  divider: {
    margin: "0 4px",
  },
  chatInput: {
    width: '100%',
    marginBottom:'36px',
    position:'fixed',
    bottom:'10px',
    borderTop: 'none !important', // Ensure the top border is removed
    '& .fai-ChatInput__inputWrapper': {
      borderTop: 'none !important', // Specifically target internal elements if needed
    },
    backgroundColor: '#fff',
    maxWidth: 'calc(100% - 28%)',
    borderRadius: '10px',
    "@media (min-width: 768px)": {
      maxWidth: "calc(100% - 28%)", // Adjustable for larger screens
    },
  },
  imagePreviewContainer: {    
    display: "flex",
    alignItems: "center",
    gap: "8px",
    margin:'8px',
    padding:'7px',
    width:'100%',
    maxWidth:'max-content',
    backgroundColor:'#f3f3f3'
  },
  chatContainer :{
    backgroundColor: '#fff',
    height: '120px',
    width: '100%',
    borderRadius: '10px',
  },
  imagePreview: {
    maxWidth: "30px",
    maxHeight: "30px",
    borderRadius: tokens.borderRadiusSmall,
  },
  imageName: {
    fontSize: tokens.fontSizeBase200,
    color: tokens.colorNeutralForeground2,
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: tokens.spacingVerticalM,
    justifyContent:'center',
    width:'100%',
    maxWidth:'calc(100% - 28%)',
    textAlign:'center'
  },
  logoImage: {
    width: "50px",
    height: "50px",
    marginRight: tokens.spacingHorizontalS,
  },
  logoText: {
    display: "flex",
    flexDirection: "column",
    fontSize:'20px',
    fontWeight:'bold',
    margin:'5px',
    textAlign:'center'
  },
  logoPara:{
    fontSize:'18px',
    fontWeight:'600',
    margin:'10px'
  }
});
function renderChatMessage(messageData: MessageData, index: number) {
  switch (messageData.type) {
    case "copilot":
      return (
        <CopilotMessageWrapper
          key={`CopilotMessage-${index}`}
          {...messageData}
        />
      );
    case "user":
      return (
        <UserMessage
          key={`UserMessage-${index}`}
          timestamp={messageData.timestamp}
          actionBar={
            <>
              <Button icon={<EditRegular />} appearance="transparent" />
              <Button icon={<BookmarkRegular />} appearance="transparent" />
              <Button icon={<ShareRegular />} appearance="transparent" />
            </>
          }
        >
          {messageData.message}
          {messageData.imageUrl && (
            <img
              src={messageData.imageUrl}
              alt="Uploaded preview"
              style={{ maxWidth: "200px", maxHeight: "200px", marginTop: "8px" }}
            />
          )}
        </UserMessage>
      );
    case "system":
      return (
        <SystemMessageWrapper key={`SystemMessage-${index}`} {...messageData} />
      );
  }
}

export interface ChatComponentProp extends InjectedIntlProps {

  setFormDataToIris?: any;
  setShowBot?: any;
  setChatBotClicked?: any;
  setIrisUtterance: any;
  setIrisAppName: any;
  setTileName: any;
  setMessageData?: any;
  setIrisPayload?: any;
}
const ChatComponent: React.FC<ChatComponentProp> = (props) => {
  let irisBot = false;
  const reduxDispatch = useDispatch();
  const [messageHistory, setMessageHistory] = React.useState<MessageData[]>(props.setMessageData || []);
  const [isLoading, setIsLoading] = React.useState(false);
  const controlRef = React.useRef<ImperativeControlPluginRef>(null);

  const [inputText, setInputText] = React.useState<string | undefined>("");
  const [suggestions, setSuggestions] = React.useState([SUGGESTIONS[0], SUGGESTIONS[1]]);
  const [tempImageUrl, setTempImageUrl] = React.useState<string | null>(null);
  const [tempImageName, setTempImageName] = React.useState<string>("");
  const[imageSummary , setImmageSummary] = React.useState<string>("")
  const [recommendedServices, setRecommendedServices] = React.useState([]);
  const [ticketCreationPrompt, setTicketCreationPrompt] = React.useState<boolean>(false); 
  const [serviceSelected, setServiceSelected] = React.useState<boolean>(false);

  const [name, setName] = React.useState("");
const [description, setDescription] = React.useState("");

const [selectedServiceName, setSelectedServiceName] = React.useState('');
const [selectedIRISUtterance, setSelectedIRISUtterance] = React.useState('');
const [selectedTileName, setSelectedTileName] = React.useState('');

const [showForm, setShowForm] = React.useState(false);
  const [selectedService, setSelectedService] = React.useState('');
  const context = React.useContext(ServiceContext);
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const onUpdate = (message: MessageStreamingData) => {
    setMessageHistory((messageHistory) =>
      updateMessageContent(
        messageHistory,
        message.responseId,
        message,
        message.loadingState
      )
    );
  };
  const llmClient = React.useRef<LLMClient>(new LLMClient(onUpdate));

  const handleFileUpload = () => {
    fileInputRef.current?.click();
  };



{imageSummary && !serviceSelected && (
  <>
    <RadioGroup>
      {(recommendedServices ?? []) 
    .slice(0, 3).map((service , index) => (
        <Radio
          key={index}
          label={service.name}
          value={service.name}
          onChange={() => handleServiceSelection(service.name,service.irisUtterance,service.tileName)}
        />
      ))}
      <Radio
        label="None of the above"
        value="none_of_the_above"
        onChange={() => handleServiceSelection("none_of_the_above","","")}
      />
    </RadioGroup>
  </>
)}

const handleServiceSelection = (serviceName: string,irisUtterance: string,tileName: string) => {
  if (serviceName === "none_of_the_above") {
    setMessageHistory((messageHistory) => [
      ...messageHistory,
      {
        type: "copilot",
        id: `copilot-${messageHistory.length}`,
        message: "Thanks for your response. You can upload a different image.",
        timestamp: formatCurrentTime(),
      },
    ]);
    setShowForm(false);
  } else {
    setSelectedService(serviceName); 
    setSelectedServiceName(serviceName);// Track selected service for display
    setSelectedIRISUtterance(irisUtterance);
    setSelectedTileName(tileName);
    setShowForm(true); 
  }
  setServiceSelected(true);
};

const renderForm = () => (
  <div style={{ padding: "16px", borderRadius: "8px", border: "1px solid #ddd", margin: "16px 0" , width:'100%',maxWidth:'750px' }}>
    <h3 style={{fontSize:'20px'}}>Thanks for choosing this service. Please confirm the details</h3>
    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      <input
        type="text"
        placeholder="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        style={{ padding: "8px", borderRadius: "4px", border: "1px solid #ddd" }}
      />
      <textarea
  placeholder="Description"
  value={description}
  onChange={(e) => setDescription(e.target.value)}
  style={{
    padding: "8px",
    borderRadius: "4px",
    border: "1px solid #ddd",
    minHeight: "80px",
    resize: "vertical"  // Allows the user to resize the textarea vertically
  }}
/>
      <Button onClick={handleData} appearance="primary">
        Submit
      </Button>
    </div>
  </div>
);
const handleData = () =>{
  console.log("name" , name);
  console.log("desxc" , description);

  setMessageHistory((messageHistory) => [
    ...messageHistory,
    {
      type: "copilot",
      id: `copilot-${messageHistory.length}`,
      message:"Thanks for the response. Your image has been processed. Ticket creation with IRIS is in progress on the right side of the screen. To create another ticket, please upload another image.",
      timestamp: formatCurrentTime(),
    },
  ]);

  handleChatBotIconClick();

  setName("");
  setDescription("");
  // setTicketCreationPrompt(true); 
  //(false);



}
const resetUploadState = () => {
  setServiceSelected(false);
  setRecommendedServices([]);
  setTicketCreationPrompt(false);
  setImmageSummary("");
};
  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setTempImageUrl(imageUrl);
      setTempImageName(file.name);
      resetUploadState(); 
      //If user does not select any service or submit
      setShowForm(false);
    }
  };


  const handleChatBotIconClick = async (
  ) => {
  
    let formDataStructure = {
      "Service Name": selectedServiceName,
      //"Service Group": "Manage Revenue",
      "Priority": "P4 - Low",
      //"Sales Unit": "USA - Commercial Unassigned",
      //"Additional visibility (CC)": "",
      //"Request type": "Clean MAL",
      "Ticket Subject": name,
      "Description": description,
  
    };
  
    irisBot = false;
    reduxDispatch(settitleLoaderName(selectedServiceName));
    reduxDispatch(setIsLoader(true));
    reduxDispatch(setIsBotError(false));
  
    // props.setShowBot(false);
    // props.setChatBotClicked(true);
    // props.setIrisUtterance("");
    // props.setIrisAppName("mcapsv2");
    // props.setTileName(selectedTileName);
    reduxDispatch(setIsBotError(false));
    // const irisutterance = selectedIRISUtterance !== null ? selectedIRISUtterance : "Americas Requests";
    var messageDataHistory = [];
    messageDataHistory.push({
      type: "copilot",
      id: `copilot-${messageDataHistory.length}`,
      message: "Thanks for the response. Your image has been processed. Ticket creation with IRIS is in progress on the right side of the screen. To create another ticket, please upload another image.",
      timestamp: formatCurrentTime(),
    });
    // setMessageHistory(messageDataHistory);
    const propsValues = {
      setShowBot: false, 
      setChatBotClicked: true, 
      setIrisUtterance: selectedIRISUtterance !== null ? selectedIRISUtterance : "Americas Requests", 
      setIrisAppName: "mcapsv2", 
      setTileName: selectedTileName, 
      setFormDataToIris: formDataStructure,
      messageData: messageDataHistory
    };
    
    props.setIrisPayload(propsValues);
    // props.setShowBot(false);
    // props.setFormDataToIris(formDataStructure);
    // props.setIrisUtterance(irisutterance);
    // props.setIrisAppName("mcapsv2");
    // props.setTileName(selectedTileName);
  };
  
  


  const handleSubmit = async (userMessage?: string) => {
    
    if (isLoading) return;
  
    const message = userMessage ?? inputText;
    if (!message && !tempImageUrl) return;
  
    setInputText("");
    controlRef.current?.setInputText("");
    setIsLoading(true);
    setSuggestions([]);
  
    setMessageHistory((messageHistory) => [
      ...messageHistory,
      {
        type: "user",
        id: `user-${messageHistory.length}`,
        message,
        timestamp: formatCurrentTime(),
        imageUrl: tempImageUrl || undefined,
      },
    ]);
  
    setMessageHistory((messageHistory) => [
      ...messageHistory,
      {
        type: "copilot",
        id: `copilot-${messageHistory.length}`,
        message: 'Analyzing image for safety...',
        timestamp: formatCurrentTime(),
      },
    ]);

    setTempImageUrl(null);

    try {
      const imageFile = await fetch(tempImageUrl)
        .then((response) => response.blob())
        .then((blob) => new File([blob], "uploaded_image.jpg", { type: blob.type }));
  
      const formData = new FormData();
      formData.append("File", imageFile);
  
      // Call ImageContentSafety API
      const safetyApiUrl = '/api/ImageProcessing/ImageContentSafety';
      const safetyResponse = await postConsumptionAPI(safetyApiUrl, formData, context.authClient);
  
      if (safetyResponse?.data?.isSafe == true) {
        const summarizeApiUrl = '/api/ImageProcessing/GetImageDescription';
        const summarizeResponse = await postConsumptionAPI(summarizeApiUrl, formData, context.authClient);

        const summary = summarizeResponse.data;
        setImmageSummary(summary);
        const imageSummary = summary;
        // Display summary from SummarizeImage API
        setMessageHistory((messageHistory) => [
          ...messageHistory,
          {
            type: "copilot",
            id: `copilot-${messageHistory.length}`,
            message: `${(summarizeResponse.data)}`,
            timestamp: formatCurrentTime(),
          },
        ]);
          // calling another API for showing top 3 recommended services
          const recommendedServices = '/api/ImageProcessing/GetRecommendedServicesForImage';
          const postData = imageSummary
          const recommendedResponse = await postConsumptionAPI(recommendedServices ,postData , context.authClient )
          console.log(recommendedResponse , "recommendedResponse");

          const uniqueServices = recommendedResponse?.data?.services.filter((service, index, self) => {
            const duplicate = self.findIndex(s => s.name === service.name && s.irisUtterance !== null);
            
            // If there's a valid duplicate (non-null irisUtterance), we keep it; else, we remove the null irisUtterance one.
            if (duplicate !== -1 && service.irisUtterance === null) {
                return false;
            }
            return true;
          });

          recommendedResponse.data.services = uniqueServices;
         
          console.log("unitservice",recommendedResponse);

          setRecommendedServices(recommendedResponse?.data?.services);

          const serviceName = recommendedResponse?.data?.services?.[0]?.name || "";  // Get the name of the first service
          const imageDescription = recommendedResponse?.data?.imageDescription || "";   // Get the image description
          setName(serviceName);
          setDescription(imageDescription);  
      } else {
        // Image not safe
        setMessageHistory((messageHistory) => [
          ...messageHistory,
          {
            type: "copilot",
            id: `copilot-${messageHistory.length}`,
            message: 'Image failed safety check. Processing stopped.',
            timestamp: formatCurrentTime(),
          },
        ]);
      }
    } catch (error) {
      setMessageHistory((messageHistory) => [
        ...messageHistory,
        {
          type: "copilot",
          id: `copilot-${messageHistory.length}`,
          message: 'Error processing image.',
          timestamp: formatCurrentTime(),
        },
      ]);
    } finally {
      setTempImageUrl(null);
      setTempImageName("");
      fileInputRef.current.value = "";
      setIsLoading(false);

    }
  };
  
  const chatContainerRef = React.useRef<HTMLDivElement>(null);
  // Effect to scroll the chat to the bottom whenever messageHistory changes
  React.useEffect(() => {
    if (chatContainerRef.current) {
      const containerHeight = chatContainerRef.current.clientHeight;
      const scrollHeight = chatContainerRef.current.scrollHeight;
    
      // Set scroll position to 80% of the scrollHeight
      const scrollPosition = scrollHeight - (scrollHeight * 0.2); // This is 80% visible
  
      chatContainerRef.current.scrollTop = scrollPosition;
    }
  }, [messageHistory, recommendedServices, showForm]); // Trigger scroll whenever the messageHistory updates

  React.useEffect(() => {
    setServiceSelected(false);
  }, [imageSummary]);

  const styles = useStyles();
  return (
    <div className={styles.provider}>
         <div className={styles.logoContainer}>
        <div className={styles.logoText}>
          <h1> <img src={copilotlogo} alt="Copilot Logo" className={styles.logoImage} />Copilot</h1>
          <div className={styles.logoPara}>
          <p>Your AI assistant for Image Processing </p>
          </div>
        </div>
        
      </div>
      <CopilotChat ref={chatContainerRef} className={styles.chat}>
        {messageHistory.map(renderChatMessage)}
        {imageSummary && !serviceSelected && (
  <>

  {recommendedServices?.length > 0 ?  <>
      <span>These are the top recommended services , Based on your image ! Please select the appropiate service</span>
    <RadioGroup>
      {recommendedServices.slice(0, 3).map((service, index) => (
        <Radio key={index} label={service.name} value={service.name} onChange={() => handleServiceSelection(service.name,service.irisUtterance,service.tileName)}  />
      ))}
      <Radio label="None of the above" value="none_of_the_above"  onChange={() => handleServiceSelection("none_of_the_above","","")}/>
    </RadioGroup>
    </> : <span>Currently there are no recommended servcies based on your image</span>}
  </>
)}
        {showForm && renderForm()}
      </CopilotChat>

      <input
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={onFileChange}
      />
      
      {tempImageUrl ? (
      <div className={styles.chatInput}>
        <div className={styles.chatContainer}>
        
        <div className={styles.imagePreviewContainer}>
          <img src={tempImageUrl} alt="Selected preview" className={styles.imagePreview} />
          <span className={styles.imageName}>{tempImageName}</span>
        </div>
        <ChatInput
          actions={
            <span className={styles.inputContainer}>
              <Button
                aria-label="attach an item"
                appearance="transparent"
                icon={<ImageBorder32Regular />}
                onClick={handleFileUpload}
              />
              <Divider className={styles.divider} vertical />
            </span>
          }
          aria-label="Copilot Chat"
          maxLength={1000}
          charactersRemainingMessage={(value) => `${value} characters remaining`}
          placeholderValue="Ask questions and work with this document"
          onChange={(e, d) => setInputText(d.value)}
          onSubmit={() => handleSubmit()}
        >
          <ImperativeControlPlugin ref={controlRef} />
        </ChatInput>
        </div>
      </div>
    ) : (
      <ChatInput
        className={styles.chatInput}
        actions={
          <span className={styles.inputContainer}>
            <Button
              aria-label="attach an item"
              appearance="transparent"
              icon={<ImageBorder32Regular />}
              onClick={handleFileUpload}
            />
            <Divider className={styles.divider} vertical />
          </span>
        }
        aria-label="Copilot Chat"
        maxLength={1000}
        charactersRemainingMessage={(value) => `${value} characters remaining`}
        placeholderValue="Ask questions and work with this document"
        onChange={(e, d) => setInputText(d.value)}
        onSubmit={() => handleSubmit()}
      >
        <ImperativeControlPlugin ref={controlRef} />
      </ChatInput>
    )}
    </div>
  );
};

export const Chat = injectIntl(ChatComponent);

