import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import {
  IStyle,
  mergeStyleSets,
  FontWeights,
  DocumentCard,
  DocumentCardImage,
  ImageFit,
  DocumentCardDetails,
  Panel,
} from "@fluentui/react";
import { FontSizes } from "@fluentui/react";
import { InjectedIntlProps } from "react-intl";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { messages } from "./Help.messages";
import './AboutHelp.css';
import { useDispatch } from "react-redux";
import { SETChileComponentName, SETHasServiceRequestTypeLogData, SETIsCustomeLog, SETLogEventData } from "../../../core/store";

export const HelpSampleLinkStyles = mergeStyleSets({
  helpSampleLink: {
    margin: "8px 0",
    display: "block",
    fontSize: FontSizes.size14,
    fontWeight: FontWeights.semibold,
  } as IStyle,
});
const cardStyles = {
  width: 200,
  margin: '0 auto 10px auto'
}
const imageStyles = {
  root: {
    selectors: {
      ':hover': {
        opacity: 0.75,
      },
    },
  },
};
const paraStyles = {
  margin: 0,
  padding: 5
}

interface OwnProps extends InjectedIntlProps {
  isAppReady: boolean;
  isOpen: any;
  dismissPanel: any;
}

type Props = OwnProps & RouteComponentProps;

const HelpComponent: React.FC<Props> = (props) => {
  const { intl } = props;
  const reduxDispatch = useDispatch();
  useEffect(() => {
    // Placeholder
    // eslint-disable-next-line
  }, []);
  const logClick=(elementName, message) =>{
    reduxDispatch(SETLogEventData({
      elementName: elementName,
      elementId: "",
      action: "click",
      message: message
    }));
    reduxDispatch(SETIsCustomeLog(true));
  }
   const helpClick = (href: string, ing: string) => {
    window.open(href, "_blank")
    reduxDispatch(SETChileComponentName("Help"));

    reduxDispatch(SETHasServiceRequestTypeLogData(true));
    switch (ing) {
      case imageUrls.gettingStartedMcapsHelp:
        logClick("Getting Started with McapsHelp", "User click on Getting Started with McapsHelp");
        break;
      case imageUrls.Createyourpersonalizedworkspace:
        logClick("Create your personalized workspace", "User click on Create your personalized workspace");
        break;
      case imageUrls.GetyourHelpSupport:
        logClick("Get your Help & Support", "User click on Get your Help & Support");
        break;
      case imageUrls.ViewMyHelpDashboard:
        logClick("View My Help Dashboard", "User click on View My Help Dashboard");
        break;
      default:
        break;
    }
  }
  const sastoken = sessionStorage.getItem("SasTokenKey");
  const [imageUrls, setImageUrls] = useState({
    ViewMyHelpDashboard: null,
    GetyourHelpSupport: null,
    Createyourpersonalizedworkspace: null,
    gettingStartedMcapsHelp: null,
  });

  useEffect(() => {
    if (sastoken) {
      setImageUrls({
        ViewMyHelpDashboard: `${process.env.REACT_APP_BLOBSASSTART + "ViewMyHelpDashboard.png" + sastoken}`,
        GetyourHelpSupport: `${process.env.REACT_APP_BLOBSASSTART + "GetyourHelpSupport.png" + sastoken}`,
        Createyourpersonalizedworkspace: `${process.env.REACT_APP_BLOBSASSTART + "Createyourpersonalizedworkspace.png" + sastoken}`,
        gettingStartedMcapsHelp: `${process.env.REACT_APP_BLOBSASSTART + "gettingStartedMcapsHelp.png" + sastoken}`,
      });
    }
  }, [sastoken]);

  const renderMain = (): JSX.Element => {
    const videoLinks = [
      {
        key: "gettingStartedMcapsHelp", href: "https://aka.ms/ProfileSetting",
        img: imageUrls.gettingStartedMcapsHelp,
        text: intl.formatMessage(messages.gettingStartedMcapsHelp)
      },

      {
        key: "Createyourpersonalizedworkspace", href: "https://aka.ms/HelpWorkspace",
        img: imageUrls.Createyourpersonalizedworkspace,
        text: intl.formatMessage(messages.Createyourpersonalizedworkspace)
      },

      {
        key: "GetyourHelpSupport", href: "https://aka.ms/HelpSection"
        , img: imageUrls.GetyourHelpSupport,
        text: intl.formatMessage(messages.GetyourHelpSupport)
      },

      {
        key: "ViewMyHelpDashboard", href: "https://aka.ms/HelpDashboard"
        , img: imageUrls.ViewMyHelpDashboard,
        text: intl.formatMessage(messages.ViewMyHelpDashboard)
      },
    ]
    return (
      <Panel
        headerText="Training" // title of the panel
        isOpen={props.isOpen} // boolean prop to control the panel visibility
        onDismiss={props.dismissPanel} // function to close the panel when user clicks outside or presses Esc
        className="Training"
        id="Training"
        closeButtonAriaLabel="Close"
      >
        <div>


          <div>Here are a few useful videos that you can refer to know more about how you can get help and support from MCAPSHelp</div>
          <br />
          {videoLinks.map(item => (
            <DocumentCard
              aria-label={item.text}
              onClick={() => helpClick(item.href, item.img)}
              style={cardStyles}
              key={item.key}
              role="link"
              tabIndex={0}
            >
              <DocumentCardImage
                styles={imageStyles}
                imageFit={ImageFit.cover}
                imageSrc={item.img}
              />
              <DocumentCardDetails>
                <p style={paraStyles}>{item.text}</p>
              </DocumentCardDetails>
            </DocumentCard>
          ))}



        </div>
      </Panel>
    );
  };

  return renderMain();
};
export const Help = withRouter(injectIntl(HelpComponent));
