// MSALV2Client.ts
import {
  PublicClientApplication,
  Configuration,
  AccountInfo,
  AuthenticationResult,
} from '@azure/msal-browser';
import { IAuthClient, IUser } from '@msx/platform-types';
import { ITelemetryClient } from '@msx/platform-types';

export class MSALV2Client implements IAuthClient {
  public readonly authContext: PublicClientApplication;
  private config: Configuration;
  private readonly telemetryClient: ITelemetryClient;
  private account: AccountInfo | null = null;
  private isLoginRequested = false;
  private isRedirectComplete = false;
  private acquireTokenRequests: {
    [key: string]: ((token: string) => void)[];
  } = {};

  private interactionInProgress = false;  // Track ongoing interactions
  constructor(config: Configuration, telemetryClient: ITelemetryClient) {
    this.telemetryClient = telemetryClient;
    this.config = config;
    this.authContext = new PublicClientApplication({
      auth: config.auth,
      cache: {
        cacheLocation: 'sessionStorage',
        ...config.cache,
      },
      system: {
        navigateFrameWait: 0,
      },
    });

    this.authContext.initialize().then(async() => {     
      try {
        if( this.isRedirectComplete === false){
            const response = await this.authContext.handleRedirectPromise();
            this.handleRedirectCompleted(response);
        }
    } catch (error) {
        console.error('handleRedirectPromise error:', error);
    } finally {
        this.isRedirectComplete = true;
    }
  })
}

  public login(ignorePrompt?: boolean): Promise<void> {
    return new Promise(async (resolve, reject) => {
      this.telemetryClient.trackTrace({ message: 'UserLogInRequested' });
      try {
        if (this.isRedirectComplete) {
          const redirectRequest = ignorePrompt
            ? { scopes: ['user.read', 'user.readbasic.all'] }
            : { scopes: ['user.read', 'user.readbasic.all'], prompt: 'select_account' };
          await this.authContext.loginRedirect(redirectRequest);
        } else {
          this.isLoginRequested = true;
        }
        resolve();
      } catch (ex) {
        this.telemetryClient.trackTrace({ message: 'UserLoginFailed' });
        reject(ex);
      }
    });
  }

  public logOut(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.telemetryClient.trackTrace({ message: 'UserLogOutRequested' });
      try {
        this.authContext.logout();
        resolve();
      } catch (ex) {
        this.telemetryClient.trackTrace({ message: 'UserLogOutFailed' });
        reject(ex);
      }
    });
  }

  public getUser(): Promise<IUser | null> {
    return new Promise((resolve, reject) => {
      try {
        const user = this.getCachedUser();
        if (!user) {
          resolve(null);
          return;
        }
        resolve({
          id: user.username,
          email: user.username,
          name: user.name || '',
          oid: user.homeAccountId,
          userObject: user,
        });
      } catch (ex) {
        reject(ex);
      }
    });
  }

  public getUserId(): Promise<string | null> {
    return new Promise(async (resolve, reject) => {
      try {
        const user = await this.getUser();
        resolve(user ? user.id : null);
      } catch (ex) {
        reject(ex);
      }
    });
  }

  public isLoggedIn(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.sleep(1000); // give a little delay to refresh user cache context
        const user = await this.getUser();
        resolve(!!user);
      } catch (ex) {
        reject(ex);
      }
    });
  }

  public acquireToken(scopes: string | string[]): Promise<string | null> {
    const resourceOrScopes = typeof scopes === 'string' ? scopes.split(',') : scopes;
    return new Promise(async (resolve) => {
      if (this.isRedirectComplete) {
        const accessToken = await this.acquireTokenSilent(resourceOrScopes);
        resolve(accessToken);
      } else {
        this.addAcquireTokenRequest(resourceOrScopes, resolve);
      }
    });
  }

  private handleRedirectCompleted(response: AuthenticationResult | null): void {
    this.account = response?.account || this.getCachedUser();
    this.isRedirectComplete = true;
    if (this.isLoginRequested && !this.account) {
      this.login().catch(console.error);
    }
    if (this.account) this.flushAcquireTokenRequests();
  }

  private sleep(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private getCachedUser(): AccountInfo | null {
    const accounts = this.authContext.getAllAccounts();
    if (!accounts || accounts.length === 0) return null;
    if (accounts.length > 1) console.warn('Multiple accounts detected, returning first account.');
    return accounts[0];
  }

  private normalizeScopes(scopes: string | string[]): string[] {
    return typeof scopes === 'string' ? [scopes + '/.default'] : scopes;
  }

  private addAcquireTokenRequest(scopes: string | string[], callback: (token: string) => void): void {
    const key = this.normalizeScopes(scopes).join(',');
    if (!this.acquireTokenRequests[key]) {
      this.acquireTokenRequests[key] = [];
    }
    this.acquireTokenRequests[key].push(callback);
  }

  private flushAcquireTokenRequests(): void {
    for (const key in this.acquireTokenRequests) {
      const scopes = key.split(',');
      this.acquireTokenSilent(scopes).then((accessToken) => {
        this.acquireTokenRequests[key].forEach(cb => cb(accessToken));
      }).catch(console.error);
    }
  }

  private async acquireTokenSilent(scopes: string | string[]): Promise<string> {
    const normalizedScopes = this.normalizeScopes(scopes);
    try {
      const response = await this.authContext.acquireTokenSilent({
        scopes: normalizedScopes,
        account: this.account!,
      });
      return response.accessToken;
    } catch (error) {
      console.error('acquireTokenSilent error', error);
      await this.authContext.acquireTokenRedirect({
        scopes: normalizedScopes,
        redirectUri: this.config.auth.redirectUri || window.location.origin,
      });
      throw error;
    }
  }
}
