import { IAppTheme } from '@msx/platform-services'

export const getStyles = (theme: IAppTheme) => {
  return {
    root: {
      marginTop: '20px',
      width: '99%',
    },
  }
};


