import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  retakeTour: {
    id: 'header.retakeTour',
    defaultMessage: 'Guided tour',
  },
  retakeTourAriaLabel: {
    id: 'header.retakeTourAriaLabel',
    defaultMessage: 'retake guided tour',
  },
  localization: {
    id: 'header.localization',
    defaultMessage: 'Language menu',
  },
  notificationToolTip: {
    id: 'header.notificationToolTip',
    defaultMessage: 'Notifications',
  },
  localizationAriaLabel: {
    id: 'header.localizationAriaLabel',
    defaultMessage: 'change Language menu',
  },
  chatBot: {
    id: 'header.chatBot',
    defaultMessage: 'Copilot',
  },
  financeSupportBotAriaLabel: {
    id: 'header.financeSupportBotAriaLabel',
    defaultMessage: 'Copilot',
  },
  feedback: {
    id: 'header.feedback',
    defaultMessage: 'Feedback',
  },
  feedbackAriaLabel:{
    id: 'header.feedbackAriaLabel',
    defaultMessage: 'Feedback',
  },
  profile: {
    id: 'header.profile',
    defaultMessage: 'Profile menu',
  },
  expandMenu: {
    id: 'header.expandMenu',
    defaultMessage: 'expanded',
  },
  commandBarAriaLabel: {
    id: 'header.commandBarAriaLabel',
    defaultMessage: 'Main header, please use the left and right arrow keys to navigate between items',
  },
  globalSearch: {
    id: 'header.globalSearch',
    defaultMessage: 'Global Search',
  },
  globalSearchPlaceHolderText: {
    id: 'header.globalSearchPlaceHolderText',
    defaultMessage: 'Search',
  },
  helpSettingsTitle: {
    id: 'header.helpSettingsTitle',
    defaultMessage: 'Help',
  },
  helpSettingsBackButton: {
    id: 'header.helpSettingsBackButton',
    defaultMessage: 'Help Panel Back Button',
  },
  helpSettingsBackButtonAriaLabel: {
    id: 'header.helpSettingsBackButtonAriaLabel',
    defaultMessage: 'Help Panel Back Button for multiple level content navigation',
  },
  settingsNotificationsTitle: {
    id: 'header.settingsNotificationsTitle',
    defaultMessage: 'Notifications',
  },
  settingsSettingsTitle: {
    id: 'header.settingsSettingsTitle',
    defaultMessage: 'Settings',
  },
  settingsSettingsPrimaryButton: {
    id: 'header.settingsSettingsPrimaryButton',
    defaultMessage: 'Save',
  },
  settingsSettingsPrimaryButtonAriaLabel: {
    id: 'header.settingsSettingsPrimaryButtonAriaLabel',
    defaultMessage: 'Save Settings',
  },
  settingsSettingsSecondaryButton: {
    id: 'header.settingsSettingsSecondaryButton',
    defaultMessage: 'Cancel',
  },
  settingsSettingsSecondaryButtonAriaLabel: {
    id: 'header.settingsSettingsSecondaryButtonAriaLabel',
    defaultMessage: 'Cancel current Settings',
  },
  noNewNotifications: {
    id: 'header.noNewNotifications',
    defaultMessage: 'You are all caught up! No new alerts or notifications.',
  },
  copilotName: {
    id: 'header.copilotName',
    defaultMessage: 'MCAPSHELP Copilot',
  },
  copilotWelcomeMessage: {
    id: 'header.copilotWelcomeMessage',
    defaultMessage: "Hello! I am your Consumption Copilot here to help answer your questions. Please find below the top questions to get started with."
  },
  copilotWelcomeSuggestion: {
    id: 'header.copilotWelcomeSuggestion',
    defaultMessage: '["Is Consumption Estimator 2.0 is complete replacement of excel based estimator ?", "What are the new capabilities of Consumption Estimator 2.0 ?", "Can I publish estimates to CPQ/Compass One ?"]'
  },
  editProfileSettingsTitle: {
    id: 'header.editProfileSettingsTitle',
    defaultMessage: 'Edit Profile',
  },

});
