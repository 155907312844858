import React, { useState, useEffect } from "react";
import {
  CopilotMessageV2 as CopilotMessage,
  Timestamp,
} from "@fluentui-copilot/react-copilot-chat";
import { Body1, Button } from "@fluentui/react-components";
import { CopilotProvider, FeedbackButtons } from "@fluentui-copilot/react-copilot";
import { useCopilotMode } from "@fluentui-copilot/react-provider";
import { Avatar } from "@fluentui/react-components";
import { CopyRegular } from "@fluentui/react-icons";
import { formatCurrentTime } from "./utils";

// Wrapper for rendering Copilot's messages
export const CopilotMessageWrapper = (props) => {
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0); // Progress bar state
  const [message, setMessage] = useState("");
  const copilotMode = useCopilotMode();

  useEffect(() => {
    // Simulate loading with progress bar
    const fetchMessage = async () => {
      setLoading(true);
      setProgress(0);

      // Update progress bar every 100ms until complete
      const interval = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 100) {
            clearInterval(interval);
            return 100;
          }
          return prev + 10;
        });
      }, 100);

      // Simulate API response delay
      setTimeout(() => {
        setMessage(props.message);
        setLoading(false);
      }, 1000);
    };

    fetchMessage();
  }, [props.message]);

  return (
    <CopilotProvider
      themeExtension={{
        colorBrandFlair1: "#107C41",
        colorBrandFlair2: "#DCF51D",
        colorBrandFlair3: "#42B8B2",
      }}
    >
      <div style={{ position: "relative", maxWidth: "750px" }}>
        <CopilotMessage
          style={{
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "10px",
            maxWidth: "750px",
            position: "relative",
            overflow: "hidden",
          }}
          avatar={
            <Avatar
              size={24}
              image={{
                src: "https://res-2-sdf.cdn.office.net/files/fabric-cdn-prod_20240411.001/assets/brand-icons/product/svg/copilot_24x1.svg",
              }}
            />
          }
          name="Copilot"
          actions={
            <>
              <Button
                appearance={copilotMode === "canvas" ? "secondary" : "transparent"}
                icon={<CopyRegular />}
              >
                {copilotMode === "canvas" ? "Copy" : ""}
              </Button>
              <FeedbackButtons />
            </>
          }
        >
          {/* Display loading message or the actual content */}
          <Body1>{loading ? "Analyzing the message..." : message}</Body1>
          
          {/* Integrated progress bar */}
          {loading && (
            <div
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                height: "4px",
                width: "100%",
                backgroundColor: "#e0e0e0",
                borderRadius: "0 0 5px 5px",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  height: "100%",
                  width: `${progress}%`,
                  background: "linear-gradient(90deg, #42a5f5, #478ed1)",
                  transition: "width 0.1s ease",
                }}
              />
            </div>
          )}
        </CopilotMessage>
      </div>
    </CopilotProvider>
  );
};

// Wrapper for rendering system messages
export const SystemMessageWrapper = (props) => {
  return (
    <div style={{ textAlign: "center", marginTop: "10px" }}>
      <Timestamp>{formatCurrentTime()}</Timestamp>
      <Body1>{props.message}</Body1>
    </div>
  );
};

