import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'errorPage.title',
    defaultMessage: 'Something went wrong',
  },
  retryText: {
    id: 'errorPage.retryText',
    defaultMessage: 'Try again',
  },
});

export default messages;
