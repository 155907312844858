import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title1: {
    id: 'learn.title-1',
    defaultMessage: `Nulla consequat massa quis enim`,
  },
  title2: {
    id: 'learn.title-2',
    defaultMessage: `Lorem ipsum dolor sit amet `,
  },
  hdrTroubleShooting: {
    id: 'learn.hdrTroubleShooting',
    defaultMessage: `Iverra quis, feugia`,
  },
  descTroubleShooting: {
    id: 'learn.descTroubleShooting',
    defaultMessage: `Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.`,
  },
  lstRequestAccess1: {
    id: 'learn.lstRequestAccess1',
    defaultMessage: `Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et`,
  },
  lstRequestAccess2: {
    id: 'learn.lstRequestAccess2',
    defaultMessage: `Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus etLorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus etLorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et`,
  },
  lstRequestAccess3: {
    id: 'learn.lstRequestAccess3',
    defaultMessage: `Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et`,
  },
  hdrRequestSupport: {
    id: 'learn.hdrRequestSupport',
    defaultMessage: `Vivamus elementum semper nisi `,
  },
  lstRequestSupport1: {
    id: 'learn.lstRequestSupport1',
    defaultMessage: `Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque `,
  },
  lstRequestSupport2: {
    id: 'learn.lstRequestSupport2',
    defaultMessage: `Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque  `,
  },
  lstRequestSupport3: {
    id: 'learn.lstRequestSupport3',
    defaultMessage: ` Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque `,
  },
  hdrRequestAccessSuplrWeb: {
    id: 'learn.hdrRequestAccessSuplrWeb',
    defaultMessage: `Tincidunt. Duis leo. Se`,
  },
  descRequestAccessSuplrWeb: {
    id: 'learn.descRequestAccessSuplrWeb',
    defaultMessage: `s in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nun`,
  },
  description1: {
    id: 'learn.description',
    defaultMessage:'Lorem ipsum Microsoft StretchX To Do, dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes.'
  },
  description2: {
    id: 'learn.description-2',
    defaultMessage :'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies.'
  },
  description3: {
    id: 'learn.description-3',
    defaultMessage: `mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rh.`,
  },
  description4: {
    id: 'learn.description-4',
    defaultMessage: `Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque .`,
  },
  description5: {
    id: 'learn.description-5',
    defaultMessage: `I’ve signed up for a Microsoft Account, but I can’t see my Account… What should I do?`,
  },
  listItem11: {
    id: 'learn.listItem11',
    defaultMessage:
      'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesq',
  },
  listItem12: {
    id: 'learn.listItem12',
    defaultMessage: 'orem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputat',
  },
  listItem21: {
    id: 'learn.listItem21',
    defaultMessage: `Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et maLorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et`,
  },
  listItem212: {
    id: 'learn.listItem212',
    defaultMessage: ` to sign-in. `,
  },
  listItem22: {
    id: 'learn.listItem22',
    defaultMessage: `Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et maLorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus etLorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et`,
  },
  listItem23: {
    id: 'learn.listItem23',
    defaultMessage: `Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et maLorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus etLorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus etLorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et`,
  },
  listItem232: {
    id: 'learn.listItem232',
    defaultMessage: `Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et ma`,
  },
  listItem31: {
    id: 'learn.listItem31',
    defaultMessage: `Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque . `,
  },
  listItem32: {
    id: 'learn.listItem32',
    defaultMessage: `Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque  `,
  },
  listItem33: {
    id: 'learn.listItem33',
    defaultMessage: `Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque  `,
  },
  listItem332: {
    id: 'learn.listItem332',
    defaultMessage: `. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque . `,
  },
  msftActiveDirectorySupportLink: {
    id: 'learn.ActiveDirectorySupportLink',
    defaultMessage: `Lorem ipsum dolor sit amet, conse.`,
  },
  here1: {
    id: 'learn.here1',
    defaultMessage: ` commodo ligula eget`,
  },
  learnBreadcrumbText: {
    id: 'learn.breadcrumbText',
    defaultMessage: 'Learn more'
  },
  homeBreadcrumbText: {
    id: 'learn.homeBreadcrumbText',
    defaultMessage: 'Home'
  },
});
