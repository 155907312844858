import { Dialog, DialogType } from "@fluentui/react/lib/Dialog";
import "./loader.css";

const Loader = ({ isLoading, setProfileLoading }) => {
  if (!isLoading) return null;

  return (
    <Dialog
        hidden={!isLoading}
        onDismiss={() => {
            setProfileLoading(false);
        }}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Please Wait...",
          subText: ""//Setting up your workspace. Please wait a moment..
        }}
        modalProps={{
          className: "preference-Loader",
          isBlocking: true,
          styles: { main: { maxWidth: 200, maxHeight: "30" } },
          // isDarkOverlay:false
        }}
        styles={(props) => ({ main: { boxShadow: "none" } })} // other props
   >
  </Dialog>
  );
};


export default Loader;