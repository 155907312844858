import React, { useMemo,useContext } from 'react';
import { DataService } from '../../app/services';
import { DataServiceContext } from '../../app/context'
import { ServiceContext } from '@msx/platform-services';

export const AppDataServiceProvider: React.FC = props => {
  const { httpClient, authClient } = useContext(ServiceContext);
  const dataService = new DataService(httpClient, authClient);

  const dataServiceContainer = useMemo(() => ({
    dataService: dataService,
  }), [dataService]);

  return (
    <DataServiceContext.Provider value={dataServiceContainer}>
      {props.children}
    </DataServiceContext.Provider>
  );
};